/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import './index.scss';
import blue from '../../../assets/images/bluebg.jpg';
import orange from '../../../assets/images/orangebg.jpg';
import img7 from '../../../assets/images/play-store.png';


const PrivacyPlicy = () => {
    return (
        <div>
            <div className='con' style={{
                backgroundImage: `url(${blue})`,
                backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'revert',
            }}>
                <div className='privacy'>
                    <div className='privacy__heading'>
                        <h1>Privacy Policy</h1>
                    </div>
                </div>
                <div className='privacy__content1'>
                    <h6 style={{ margin: '0', padding: '10px 0' }}>Last update May 18, 2022</h6>
                    <p>Welcome to Chattybao. Chattybao Technologies Private Limited ("us", "we", or "our") operates the chattybao.com website and the chattybao mobile application (herein after referred to as the "Service"). Our Privacy Policy explains how we collect, use, disclose, and protect
                        information that applies to our Service, and your choices about the collection and use of your information. This policy sets out how chattybao collects and uses the information that we collect about you when you use the Chattybao services. This policy also explains the
                        choices that you can make about the way that we use your information.</p>
                    <h6>DEFINITIONS</h6>
                    <p>- Personal Data Personal Data means data about a living individual who can be identified from those data</p>
                    <p>- Usage Data Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself.</p>
                    <p>- Cookies Cookies are small pieces stored on your device (computer or mobile device)</p>


                    <h6>INFORMATION WE COLLECT AND ITS USE</h6>
                    <p>We collect the following types of information about you:</p>
                    <p>Information you provide us directly</p>
                    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include Email Address, Phone Number, Business Name, First and Last Name, Cookies and Usage Data.</p>
                    <h6>Usage Data</h6>
                    <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data").</p>
                    <p>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers
                        and other diagnostic data.</p>
                    <p>When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser
                        you use, unique device identifiers and other diagnostic data.</p>

                    <h6>Cookies Information</h6>
                    <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
                    <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track
                        information and to improve and analyse our Service.</p>
                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                    <h6>Use Of Data</h6>
                    <h6>Chattybao Technologies Private Limited uses the collected data for various purposes:</h6>
                    <p>a) To provide and maintain our Service</p>
                    <p>b) To notify you about changes to our Service</p>
                    <p>c) To allow you to participate in interactive features of our Service when you choose to do so</p>
                    <p>d) To provide customer support</p>
                    <p>e) To gather analysis or valuable information so that we can improve our Service</p>
                    <p>f) To monitor the usage of our Service</p>
                    <p>g) To detect, prevent and address technical issues</p>
                    <p>h) To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</p>
                    <h6>RETENTION OF DATA</h6>
                    <p>Chattybao Technologies Private Limited will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we
                        are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.</p>
                    <p>Chattybao Technologies Private Limited will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we
                        are legally obligated to retain this data for longer time periods.</p>
                    <h6>Cookies Information</h6>
                    <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
                    <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track
                        information and to improve and analyse our Service.</p>
                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                    <h6>TRANSFER OF DATA</h6>
                    <p>Your information, including Personal Data, may be transferred to — and maintained on —
                        computers located outside of your state, province, country or other governmental jurisdiction
                        where the data protection laws may differ from those of your jurisdiction.</p>
                    <p>If you are located outside ………………………, and choose to provide information to us, please note
                        that we transfer the data, including Personal Data, to ……………….., India and process it there.</p>
                    <p>Your consent to this Privacy Policy followed by your submission of such information represents
                        your agreement to that transfer.</p>
                    <p>Chattybao Technologies Private Limited will take all the steps reasonably necessary to ensure
                        that your data is treated securely and in accordance with this Privacy Policy and no transfer of
                        your Personal Data will take place to an organisation or a country unless there are adequate
                        controls in place including the security of your data and other personal information.</p>
                    <h6>DELETION OF DATA</h6>
                    <p>You have the right to request the deletion of Your Personal Data, subject to certain exceptions.
                        Once We receive and confirm Your request, We will delete (and direct Our Service Providers to
                        delete) Your personal information from our records</p>
                    <p>* You can yourself delete the catalogs, products and other inventory information</p>
                    <p>* Once you delete the data we don't store any backups of the same data.</p>
                    <p>* The request may take upto 7 working days to be executed</p>
                    <h6>Who you may choose to share your User Content with</h6>
                    <h6>Business Transaction</h6>
                    <p>If Chattybao Technologies Private Limited is involved in a merger, acquisition or asset sale, your
                        Personal Data may be transferred. We will provide notice before your Personal Data is
                        transferred and becomes subject to a different Privacy Policy.</p>
                    <h6>Disclosure for Law Enforcemen</h6>
                    <p>Under certain circumstances, Chattybao Technologies Private Limited may be required to
                        disclose your Content if required to do so by law or in response to valid requests by public
                        authorities (e.g. a court or a government agency).</p>
                    <h6>Legal Requirements</h6>
                    <p>Chattybao Technologies Private Limited may disclose your Personal Data in the good faith belief
                        that such action is necessary to:</p>
                    <p style={{ margin: '0' }}>
                        a) To comply with a legal obligation<br />
                        b) To protect and defend the rights or property of Chattybao Technologies Private Limited<br />
                        c) To prevent or investigate possible wrongdoing in connection with the Service<br />
                        d) To protect the personal safety of users of the Service or the public<br />
                        e) To protect against legal liability
                    </p>
                </div>
            </div>
            <div className='con' style={{
                backgroundImage: `url(${orange})`,
                backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'revert',
                paddingTop: '0'
            }}>
                <div className='privacy__content2'>
                    <h6 style={{ margin: '0', padding: '10px 0' }}>Security of Data</h6>
                    <p> The security of your data is important to us but remember that no method of transmission over
                        the Internet or method of electronic storage is 100% secure. While we strive to use commercially
                        acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                    <p>You have the right to complain to a Data Protection Authority about our collection and use of your
                        Personal Data. For more information, please contact your local data protection authority in the
                        European Economic Area (EEA).</p>
                    <h6>Service Providers</h6>
                    <p>We may employ third party companies and individuals to facilitate our Service ("Service
                        Providers"), provide the Service on our behalf, perform Service-related services or assist us in
                        analysing how our Service is used.
                        <br />
                        <br />
                        These third parties have access to your Personal Data only to perform these tasks on our behalf
                        and are obligated not to disclose or use it for any other purpose.
                    </p>
                    <h6>Analytics</h6>
                    <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
                    <h6>Google Analytics</h6>
                    <p>Google Analytics is a web analytics service offered by Google that tracks and reports website
                        traffic. Google uses the data collected to track and monitor the use of our Service. This data is
                        shared with other Google services. Google may use the collected data to contextualise and
                        personalise the ads of its own advertising network. You can opt-out of having made your activity
                        on the Service available to Google Analytics by installing the Google Analytics opt-out browser
                        add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from
                        sharing information with Google Analytics about visits activity.
                        <br />
                        <br />
                        For more information on the privacy practices of Google, please visit the Google Privacy & Terms
                        web page: https://policies.google.com/privacy?hl=en
                    </p>
                    <h6>Children's Privacy</h6>
                    <p>Chattybao does not knowingly collect or solicit personal information from children under the age
                        of 18 and the Service and its content are not directed at children under the age of 18. In the event
                        that we learn that we have collected personal information from a child under age 18 without
                        verification of parental consent, we will delete that information as quickly as possible. If you
                        believe that we might have any information from or about a child under 18. Please contact us at
                        ………………………………</p>
                    <h6>How long we keep your User Content</h6>
                    <p>Following termination or deactivation of your User account, Chattybao may retain your profile
                        information and User Content for a commercially reasonable time, and for as long as we have a
                        valid purpose to do so. In particular, Chattybao may retain your information for the purpose of
                        comply with its legal and audit obligations, and for backup and archival purposes.</p>
                    <h6>Any Updates to this Privacy Policy</h6>
                    <p>We will let you know via email and/or a prominent notice on our Service, prior to the change
                        becoming effective and update the "effective date" at the top of this Privacy Policy. Please review
                        this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when
                        they are posted on this page.</p>
                    <h6>Contact Us</h6>
                    <p>For any questions about this Privacy Policy, please contact us<br />
                        By Email: admin@chattybao.com<br />
                        By Mail: Customer Grievance Officer<br />
                        C-17, Basement, B-3, Guru Nanak Pura, Laxmi Nagar<br />
                        New Delhi 110092<br />
                        We will respond to all requests, inquiries or concerns within a reasonable period.</p>
                </div>
                <div style={{ padding: '60px 0' }} className='col-n'>
                    <div className='con-2__content'>
                        <h1>Have a Business ?</h1>
                        <h2>5 minute Sign up</h2>
                        <div className='con-2__link'>
                            <a href='https://play.google.com/store/apps/details?id=com.goping.merchant' target='_blank'><img src={img7} alt='' /></a>

                            <div className='con-2__link-content'>
                                <a href='https://play.google.com/store/apps/details?id=com.goping.merchant' target='_blank'><p>Download Now</p></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPlicy;