/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-undef */
import React from "react";
import { useHistory } from "react-router";
import { HashLink } from "react-router-hash-link";
import blue from "../../../assets/images/bluebg.jpg";
import logo from "../../../assets/images/logo.png";
// import darkLogo from "../../../assets/images/logoDark.png";
import whiteLogo from "../../../assets/images/logoWhite.png";
import "./index.scss";

const Header = () => {
	const history = useHistory();

	const handleClickScroll = () => {
		history.push("/");
		window.scrollTo(0, 0);
	};

	return (
		<div>
			<div
				className="header"
				style={{
					backgroundImage: `url(${blue})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					width: "100%",
					backgroundAttachment: "fixed",
					backgroundPosition: "revert",
				}}
			>
				<header className="header-main header-light fixed-top">
					<nav className="navbar navbar-expand-lg navbar-light">
						<div className="container">
							<div className="header__image">
								<img
									onClick={handleClickScroll}
									src={logo}
									alt=""
									className="dark-logo"
								/>
								<img
									onClick={handleClickScroll}
									src={whiteLogo}
									alt=""
									className="white-logo"
								/>
							</div>

							<button
								className="navbar-toggler"
								type="button"
								data-toggle="collapse"
								data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon">
									<i className="fa fa-bars "></i>
									<i className="fa fa-close"></i>
								</span>
							</button>

							<div
								className="collapse navbar-collapse"
								id="navbarSupportedContent"
							>
								<div className="navbar-dropdown">
									<div className="navbar-hide">
										<ul className="navbar-nav ml-auto">
											<li className="nav-item">
												<HashLink
													smooth
													to="#how-it-works"
													onClick={handleClickScroll}
													style={{ cursor: "pointer" }}
													className="nav-link"
												>
													How it Works
												</HashLink>
											</li>

											<li className="nav-item">
												<HashLink
													smooth
													to="#have-a-business"
													onClick={handleClickScroll}
													style={{ cursor: "pointer" }}
													className="nav-link"
												>
													Have a Business ?
												</HashLink>
											</li>

											<li className="nav-item">
												<a
													onClick={() => history.push("/contact-us")}
													style={{ cursor: "pointer" }}
													className="nav-link"
												>
													Contact Us
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</header>
			</div>
		</div>
	);
};

export default Header;
